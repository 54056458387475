// import moment from "@/helpers/moment";
// import messageComponent from "@/config/message";
import config from "@/config";

const mixin = {
  methods: {
    onLockDate($this, data) {
      return $this.$CryptoJS.AES.encrypt(
        JSON.stringify(data),
        config.secretPass
      ).toString();
    },

    //   crrWeekStartAndEndDay() {
    //     let dat = new Date();
    //     var startOfWeek = moment(dat).startOf("week").format("YYYY-MM-DD");
    //     var endOfWeek = moment(dat).endOf("week").format("YYYY-MM-DD");
    //     return {
    //       first: startOfWeek.toString(),
    //       last: endOfWeek.toString(),
    //     };
    //   },
    //   getPeriodeOfYear() {
    //     const startOfMonth = moment().startOf("year").format("YYYY-MM-DD hh:mm");
    //     const endOfMonth = moment().endOf("year").format("YYYY-MM-DD hh:mm");
    //     return { startOfMonth: startOfMonth, endOfMonth: endOfMonth };
    //   },
    //   groupeByElement(collection, property) {
    //     var i = 0,
    //       val,
    //       index,
    //       values = [],
    //       result = [];
    //     for (; i < collection.length; i++) {
    //       val = collection[i][property];
    //       index = values.indexOf(val);
    //       if (index > -1) result[index].push(collection[i]);
    //       else {
    //         values.push(val);
    //         result.push([collection[i]]);
    //       }
    //     }
    //     return result;
    //   },
    //   correctDate(dat) {
    //     if (dat) {
    //       return moment(new Date(dat)).local().format("DD-MM-YYYY HH:mm:ss");
    //     } else {
    //       return "";
    //     }
    //   },

    //   currentDateTime() {
    //     return moment().local().format("DD-MM-YYYY HH:mm:ss");
    //   },

    //   getNextWorkingDay(currentDate) {
    //     require("moment-business-time");
    //     return moment(currentDate).nextWorkingDay(); // Prochain jour ouvré
    //   },

    //   getWorksDays(a, b) {
    //     var start = moment(a);
    //     var end = moment(b);

    //     const daysOfWeekCount = {
    //       0: 0,
    //       1: 0,
    //       2: 0,
    //       3: 0,
    //       4: 0,
    //       5: 0,
    //       6: 0,
    //     };

    //     while (start < end) {
    //       daysOfWeekCount[start.day()] = daysOfWeekCount[start.day()] + 1;
    //       start.add(1, "day");
    //     }

    //     return (
    //       moment(b).diff(moment(a), "days") -
    //       daysOfWeekCount["5"] -
    //       daysOfWeekCount["6"]
    //     );
    //   },
    //   getPeriodDate(startDate, endDate, day) {
    //     let current = moment(startDate).clone();
    //     let result = [];
    //     while (current.day(7 + day).isSameOrBefore(moment(endDate))) {
    //       result.push(current.clone());
    //     }
    //     return result.map((m) => m.format("YYYY-MM-DD"));
    //   },
    //   displayServerDate(servDate) {
    //     return moment(servDate).format("DD/MM/YYYY");
    //   },
    //   formatServerDate(servDate) {
    //     return moment(servDate).format("YYYY-MM-DD");
    //   },
    //   formatServerDateWithTime(servDate) {
    //     return moment(servDate).format("YYYY-MM-DD HH:mm:ss");
    //   },
    //   getDateTimeFromTimeStamp(timestampVal) {
    //     return moment(parseInt(timestampVal)).format("DD/MM/YYYY [à] HH:mm");
    //   },
    //   remainsMonthNumber() {
    //     const dateActuelle = moment();
    //     const moisRestants = 12 - dateActuelle.month();
    //     return moisRestants;
    //   },
    //   valideVacationDate(dataString) {
    //     return moment(dataString).subtract(1, "days").format("YYYY-MM-DD");
    //   },
    //   filterJsonTable(table, key) {
    //     let listSort = table;
    //     let res = listSort.sort((a, b) => {
    //       return a[key] > b[key] ? 1 : -1;
    //     });
    //     return res;
    //   },
    //   message(composant, type) {
    //     let msg = {};
    //     msg = { ...messageComponent[composant][type] };
    //     return msg;
    //   },
    //   filterJsonTableByLevel(table, level1, level2, level3) {
    //     let listSort = [...table];
    //     if (!level3 == "") {
    //       let res = listSort.sort((a, b) => {
    //         return a[level1][level2][level3].toLowerCase() >
    //           b[level1][level2][level3].toLowerCase()
    //           ? 1
    //           : -1;
    //       });
    //       return res;
    //     } else {
    //       if (!level2 == "") {
    //         let res = listSort.sort((a, b) => {
    //           return a[level1][level2].toLowerCase() >
    //             b[level1][level2].toLowerCase()
    //             ? 1
    //             : -1;
    //         });
    //         return res;
    //       } else {
    //         let res = listSort.sort((a, b) => {
    //           return a[level1].toLowerCase() > b[level1].toLowerCase() ? 1 : -1;
    //         });
    //         return res;
    //       }
    //     }
    //   },
    //   exportToData(
    //     dataList,
    //     dataTitles,
    //     dataHeaderVal,
    //     fileName,
    //     autoWidth,
    //     bookType
    //   ) {
    //     import("@/vendor/Export2Excel").then((excel) => {
    //       const list = dataList;
    //       const data = this.formatJson(dataHeaderVal, list);
    //       excel.export_json_to_excel({
    //         header: dataTitles,
    //         data,
    //         filename: fileName,
    //         autoWidth: autoWidth,
    //         bookType: bookType,
    //       });
    //     });
    //   },
    //   s2ab(s) {
    //     var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    //     var view = new Uint8Array(buf); //create uint8array as viewer
    //     for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    //     return buf;
    //   },
    //   exportProduct(data) {
    //     var XLSX = require("xlsx");
    //     var FileSaver = require("file-saver");

    //     var wb = XLSX.utils.book_new();

    //     wb.Props = {
    //       Title: "Inventaire DAYO RH",
    //       Subject: "Inventaire",
    //       Author: "DAYO RH",
    //     };

    //     wb.SheetNames.push("Etat des produits");
    //     wb.SheetNames.push("Etat des demandes");
    //     wb.SheetNames.push("Etat des distributions");
    //     wb.SheetNames.push("Etat des approvisionnements");

    //     var wproduct = XLSX.utils.json_to_sheet(data.products);
    //     wb.Sheets["Etat des produits"] = wproduct;

    //     var wproductasking = XLSX.utils.json_to_sheet(data.asking_product);
    //     wb.Sheets["Etat des demandes"] = wproductasking;

    //     var wproductdistribut = XLSX.utils.json_to_sheet(data.sharing_product);
    //     wb.Sheets["Etat des distributions"] = wproductdistribut;

    //     var wappro = XLSX.utils.json_to_sheet(data.approvisionnement);
    //     wb.Sheets["Etat des approvisionnements"] = wappro;

    //     var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    //     FileSaver.saveAs(
    //       new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
    //       `Inventaire DAYO RH.xlsx`
    //     );
    //   },
    //   formatJson(filterVal, jsonData) {
    //     return jsonData.map((v) =>
    //       filterVal.map((j) => {
    //         return v[j];
    //       })
    //     );
    //   },

    //   lastLogin(lastLoginAt) {
    //     let title = "Dernière connexion";
    //     let text = "Néant";
    //     if (lastLoginAt) {
    //       text = moment(parseInt(lastLoginAt, 10)).format("DD/MM/YYYY [à] HH:mm");
    //     }
    //     return {
    //       title,
    //       text: "Le " + text,
    //     };
    //   },
    //   userDateElement(data) {
    //     let { whoCreated, whoUpdated, createdAt, updatedAt } = data;
    //     let textWhoCreated = "Néant";
    //     let textWhoUpdated = "Néant";
    //     let textCreatedAt = "Néant";
    //     let textUpdatedAt = "Néant";

    //     if (createdAt) {
    //       textCreatedAt = moment(parseInt(createdAt, 10)).format(
    //         "DD/MM/YYYY [à] HH:mm"
    //       );
    //     }
    //     if (updatedAt) {
    //       textUpdatedAt = moment(parseInt(updatedAt, 10)).format(
    //         "DD/MM/YYYY [à] HH:mm"
    //       );
    //     }
    //     if (whoCreated) {
    //       textWhoCreated = `${whoCreated.person.firstname} ${whoCreated.person.lastname}`;
    //     }
    //     if (whoUpdated) {
    //       textWhoUpdated = `${whoUpdated.person.firstname} ${whoUpdated.person.lastname}`;
    //     }

    //     return {
    //       createdAt: `Par ${textWhoCreated}, le ${textCreatedAt}`,
    //       updatedAt: `Par ${textWhoUpdated}, le ${textUpdatedAt}`,
    //     };
    //   },
    //   dateElement(data) {
    //     let { createdAt, updatedAt, birthday } = data;
    //     let title = "Informations";
    //     let textCreatedAt = "Néant";
    //     let textUpdatedAt = "Néant";
    //     let textBirthday = "Néant";

    //     if (createdAt) {
    //       textCreatedAt = moment(parseInt(createdAt, 10)).format(
    //         "DD/MM/YYYY [à] HH:mm"
    //       );
    //     }
    //     if (updatedAt) {
    //       textUpdatedAt = moment(parseInt(updatedAt, 10)).format(
    //         "DD/MM/YYYY [à] HH:mm"
    //       );
    //     }
    //     if (birthday) {
    //       textBirthday = moment(parseInt(birthday, 10)).format("DD/MM/YYYY");
    //     }

    //     let text = `Création: le ${textCreatedAt} Modification: le ${textUpdatedAt}`;

    //     let createdAtInfo = ` ${textCreatedAt} `;
    //     let birthdayInfo = ` ${textBirthday} `;

    //     return {
    //       title,
    //       text,
    //       createdAtInfo,
    //       birthdayInfo,
    //     };
    //   },
    //   aboutElement(data) {
    //     let { whoCreated, whoUpdated, createdAt, updatedAt } = data;
    //     let title = "Informations";
    //     let textWhoCreated = "Néant";
    //     let textWhoUpdated = "Néant";
    //     let textCreatedAt = "Néant";
    //     let textUpdatedAt = "Néant";

    //     if (createdAt) {
    //       textCreatedAt = moment(parseInt(createdAt, 10)).format(
    //         "DD/MM/YYYY [à] HH:mm"
    //       );
    //     }
    //     if (updatedAt) {
    //       textUpdatedAt = moment(parseInt(updatedAt, 10)).format(
    //         "DD/MM/YYYY [à] HH:mm"
    //       );
    //     }
    //     if (whoCreated) {
    //       try {
    //         textWhoCreated = `${whoCreated.person.firstname} ${whoCreated.person.lastname}`;
    //       } catch (error) {
    //         textWhoCreated = `Néant`;
    //       }
    //     }
    //     if (whoUpdated) {
    //       try {
    //         textWhoUpdated = `${whoUpdated.person.firstname} ${whoUpdated.person.lastname}`;
    //       } catch (error) {
    //         textWhoUpdated = `Néant`;
    //       }
    //     }

    //     let text = `Création: ${textWhoCreated}, le ${textCreatedAt} Modification: ${textWhoUpdated}, le ${textUpdatedAt}`;

    //     let createdAtInfo = ` ${textCreatedAt} `;

    //     return {
    //       title,
    //       text,
    //       createdAtInfo,
    //     };
    //   },
    //   convertDateToString(crrDate) {
    //     var d = new Date(crrDate),
    //       month = "" + (d.getMonth() + 1),
    //       day = "" + d.getDate(),
    //       year = d.getFullYear();

    //     if (month.length < 2) month = "0" + month;
    //     if (day.length < 2) day = "0" + day;

    //     return [day, month, year].join("/");
    //   },

    //   formatDateFileName(crrDate) {
    //     var d = new Date(crrDate),
    //       month = "" + (d.getMonth() + 1),
    //       day = "" + d.getDate(),
    //       year = d.getFullYear();

    //     if (month.length < 2) month = "0" + month;
    //     if (day.length < 2) day = "0" + day;

    //     return [day, month, year].join("-");
    //   },

    //   formatDate(dataString) {
    //     if (dataString != undefined) {
    //       var p = dataString.split(/\D/g);
    //       return [p[2], p[1], p[0]].join("/");
    //     }
    //   },
  },
};

export default mixin;
