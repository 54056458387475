import { SEND_MESSAGE } from "./mutation-types";

// import Vue from "vue";

export default {
  [SEND_MESSAGE](state, message) {
    state.message = message;
  },
  // [REMOVE_ROOMS](state, id) {
  //   const index = state.rooms.findIndex((el) => el.id === id);
  //   state.rooms.splice(index, 1);
  // },
  // [FETCH_ROOMS](state, data) {
  //   state.rooms = data;
  // },

  // [PUT_ROOMS](state, data) {
  //   const idx = state.rooms.findIndex((el) => el.id == data.id);
  //   Vue.set(state.rooms, idx, data);
  // },

  // ADD_EVENT: (state, event) => {
  //   state.events.push(event);
  // },
  // UPDATE_EVENT: (state, { id, title, start, end }) => {
  //   let index = state.events.findIndex((_event) => _event.id == id);

  //   state.events[index].title = title;
  //   state.events[index].start = start;
  //   state.events[index].end = end;
  // },
};
