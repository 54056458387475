<template>
  <div id="app">
    <SiteHeader />
    <div id="main-content">
      <router-view />
    </div>
    <SocialChat v-if="displayCellInfo" icon :attendants="channels">
      <p slot="header">Assistance personnalisée</p>

      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <small slot="footer">
        <h4>Heures d'ouverture</h4>
        <p>Lundi - Vendredi : 8h00 - 12h30 & 13h00 - 16h30</p>
        <p>Samedi & Dimanche: Fermé</p>
      </small>
    </SocialChat>
    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from "./components/SiteHeader.vue";
import SiteFooter from "./components/SiteFooter.vue";
import { SocialChat } from "vue-social-chat";

export default {
  props: {},
  name: "App",
  components: {
    SiteHeader,
    SiteFooter,
    SocialChat,
  },
  data() {
    return {
      displayCellInfo: false,
      channels: [
        {
          app: "whatsapp",
          label: "Accueil",
          name: "Standard",
          number: "22952529898",
          avatar: {
            src: require("@/assets/images/woman.png"),
            alt: "Standard",
          },
        },
        {
          app: "whatsapp",
          label: "Support Commercial",
          name: "Support Commercial",
          number: "22997627157",
          avatar: {
            src: require("@/assets/images/woman.png"),
            alt: "Support Commercial",
          },
        },
        {
          app: "whatsapp",
          label: "Support Sinistre",
          name: "Support Sinistre",
          number: "22967294366",
          avatar: {
            src: require("@/assets/images/man.png"),
            alt: "Support Sinistre",
          },
        },
        {
          app: "whatsapp",
          label: "Support Informatique",
          name: "Support Informatique",
          number: "22997298195",
          avatar: {
            src: require("@/assets/images/man.png"),
            alt: "Support Informatique",
          },
        },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#main-content {
  padding-top: 70px;
}

.vsc-popup-button {
  background-color: #47a8ff !important;
}

.vsc-popup-header {
  background-color: #47a8ff !important;
  text-align: center;
}
</style>
