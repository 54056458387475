import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  base: "/",
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: () => import("@/views/HomePage.vue"),
    },
    {
      path: "/services",
      name: "ServicesPage",
      component: () => import("@/views/ServicesPage.vue"),
    },
    {
      path: "/branches",
      name: "BranchesPage",
      component: () => import("@/views/BranchesPage.vue"),
    },
    {
      path: "/news",
      name: "NewsPage",
      component: () => import("@/views/NewsPage.vue"),
    },
  ],
});

Vue.router = router;
export default router;
