/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { AUTH_TOKEN_KEY } from "@/constants";

export default ($http, $config) => {
  const $api = $http.create({
    baseURL: $config.serverURL,
    headers: { "Content-Type": "application/json" },
  });

  $api.interceptors.request.use(
    (config) => {
      const authToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
      if (authToken) {
        //config.headers["Access-Token"] = sessionStorage.getItem(AUTH_TOKEN_KEY);
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /**
   * PUT Evaluation criteria Group
   * @param {*} data
   */

  const sendMessage = (data) => {
    return $api.post(`/${$config.apiVersion}sendus/notification`, data);
  };

  const sendProductMessage = (data) => {
    return $api.post(`/${$config.apiVersion}sendus/product`, data);
  };

  return {
    sendMessage,
    sendProductMessage,
  };
};
