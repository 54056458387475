import { SEND_MESSAGE } from "./mutation-types";

import service from "@/service";
import config from "@/config";
import axios from "axios";
import mixins from "@/mixins/mixin";

const $service = service(axios, config);

export default {
  async applySendMessage({ commit }, data) {
    let dept = await $service
      .sendMessage({
        data: mixins.methods.onLockDate(data._this, data.data),
      })
      .then((res) => {
        return {
          data: res.data,
          status: res.status,
        };
      })
      .catch((error) => {
        return {
          msg: error.response.data.message,
          status: error.response.data.code,
        };
      });
    if (dept.status == 200) {
      commit(SEND_MESSAGE, dept.data);
      return dept;
    } else {
      return { ...dept, status: 400 };
    }
  },

  async applySendProductMessage({ commit }, data) {
    let dept = await $service
      .sendProductMessage({
        data: mixins.methods.onLockDate(data._this, data.data),
      })
      .then((res) => {
        return {
          data: res.data,
          status: res.status,
        };
      })
      .catch((error) => {
        return {
          msg: error.response.data.message,
          status: error.response.data.code,
        };
      });
    if (dept.status == 200) {
      commit(SEND_MESSAGE, dept.data);
      return dept;
    } else {
      return { ...dept, status: 400 };
    }
  },

  //     async applyGetAllRooms({ commit }, data) {
  //         let dept = await $service
  //             .getRoomList(data)
  //             .then(res => {
  //                 return {
  //                     data: res.data,
  //                     status: res.status
  //                 };
  //             })
  //             .catch(error => {
  //                 return {
  //                     msg: error.response.data.message,
  //                     status: error.response.data.code
  //                 };
  //             });
  //         if (dept.status == 200) {
  //             commit(FETCH_ROOMS, dept.data);
  //             return dept;
  //         }
  //         return {...dept, status: 400 }
  //     },

  //     async applyAddRoom({ commit }, data) {
  //         let dept = await $service.addRoom(data).then(res => {
  //                 return {
  //                     data: res.data,
  //                     status: res.status
  //                 };
  //             })
  //             .catch(error => {
  //                 return {
  //                     msg: error.response.data.message,
  //                     status: error.response.data.code
  //                 };
  //             });
  //         if (dept.status == 200) {
  //             commit(ADD_ROOMS, dept.data)
  //             return dept;
  //         } else {
  //             return {...dept, status: 400 };
  //         }
  //     },

  //     async applyPutRoom({ commit }, data) {
  //         let dept = await $service.putRoom(data).then(res => {
  //                 return {
  //                     data: res.data,
  //                     status: res.status
  //                 };
  //             })
  //             .catch(error => {
  //                 return {
  //                     msg: error.response.data.message,
  //                     status: error.response.data.code
  //                 };
  //             });
  //         if (dept.status == 200) {
  //             commit(PUT_ROOMS, dept.data)
  //             return dept;
  //         } else {
  //             return {...dept, status: 400 };
  //         }
  //     },

  //   async applyRemoveRoom({ commit }, data) {
  //     let Room = await $service
  //       .removeRoom(data)
  //       .then(res => {
  //         return {
  //           data: res.data,
  //           status: res.status
  //         };
  //       })
  //       .catch(error => {
  //         return {
  //           msg: error.response.data.message,
  //           status: error.response.data.code
  //         };
  //       });
  //     if (Room.status == 200) {
  //       commit(REMOVE_ROOMS, data.mrhMeetingRoomId);
  //       return Room;
  //     } else {
  //       return { ...Room, status: 400 };
  //     }
  //   },
};
