<template>
  <footer>
    <div class="footer-content">
      <div class="footer-column">
        <h4>Informations légales</h4>
        <ul>
          <li>
            <router-link to="/">CGU</router-link>
          </li>
          <li><router-link to="/">Mention légale</router-link></li>
          <li>
            <router-link to="/">Politique de confidentialité</router-link>
          </li>
        </ul>
      </div>

      <div class="footer-column">
        <h4>Nos Services</h4>
        <ul>
          <li>
            <router-link to="/services?serviceId=caution"
              >Assurance Crédit Caution</router-link
            >
          </li>
          <li>
            <router-link to="/services?serviceId=automobile"
              >Assurances Dommages</router-link
            >
          </li>
          <li>
            <router-link to="/services?serviceId=vie"
              >Assurances Vie</router-link
            >
          </li>
        </ul>
      </div>

      <div class="footer-column">
        <h4>Liens Utiles</h4>
        <ul>
          <li><router-link to="/">Accueil</router-link></li>
          <li><router-link to="/services">Nos Services</router-link></li>
          <li><router-link to="/branches">Nos Filiales</router-link></li>
          <li><router-link to="/news">Actualités</router-link></li>
          <li v-if="displayCellInfo">
            <router-link to="/">Carrière</router-link>
          </li>
          <li v-if="displayCellInfo">
            <router-link to="/">A Propos</router-link>
          </li>
          <li v-if="displayCellInfo">
            <router-link to="/">Contactez-nous</router-link>
          </li>
          <li v-if="displayCellInfo"><router-link to="/">FAQ</router-link></li>
        </ul>
      </div>

      <div class="footer-column">
        <h4>Suivez-nous</h4>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61556663485982&mibextid=ZbWKwL"
              >Facebook</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://x.com/dayo_group?t=9k0qzlrvkrRuyMuNqZyVrw&s=09"
              >X
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/dayo-group/"
              >LinkedIn</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/dayo_group?igsh=bG9iYXZpYjJlYXV6"
              >Instagram</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.tiktok.com/@dayo_group?_t=8oVYD4eTxJd&_r=1"
              >TikTok</a
            >
          </li>
        </ul>
      </div>
    </div>

    <p class="copyright">&copy; 2024 DAYO. Tous droits réservés.</p>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  data() {
    return {
      displayCellInfo: false,
    };
  },
};
</script>

<style scoped>
footer {
  position: relative;
  background-color: #023672;
  color: white;
  padding-top: 60px; /* Space for wave */
  text-align: center;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 0;
}

.footer-column {
  flex: 1 1 100%; /* Chaque colonne prendra 100% de la largeur sur mobile */
  max-width: 250px; /* Largeur maximale de chaque colonne */
  margin-bottom: 20px;
  text-align: left;
}

.footer-column h4 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .footer-column {
    flex: 1 1 auto; /* Les colonnes s'adapteront en fonction de l'espace disponible */
    margin-right: 20px;
  }
}
</style>
