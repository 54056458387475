// import { mixin } from "@/mixins/mixin";

export default {
  messageSent: (state) => {
    return state.message;
  },
  //   roomData: (state) => {
  //     return state.room;
  //   },
  //   EVENTS: (state) => {
  //     return state.events;
  //   },
};
