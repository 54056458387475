import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/styles/main.scss";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueCryptojs from "vue-cryptojs";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// import VueGtag from "vue-gtag";

Vue.use(VueAwesomeSwiper);

Vue.use(VueSweetalert2);
// Vue.use(VueGtag, {
//   config: {
//     id: "G-EW761J8W67",
//     params: {
//       anonymize_ip: false,
//     },
//   },
// });
Vue.config.productionTip = false;
Vue.use(VueCryptojs);
Vue.use(Vuesax, {
  colors: {
    primary: "rgb(0, 127, 225)", // Blue
    secondary: "rgb(250, 127, 0)", // Orange
    accent: "rgb(225, 0, 127)", // Pink
    success: "rgb(23, 201, 100)", // Green (for success)
    danger: "rgb(242, 19, 93)", // Red (for danger)
    warning: "rgb(255, 130, 0)", // Orange (for warning)
    dark: "rgb(36, 33, 69)", // Dark color for text or backgrounds
  },
});
import VueSocialChat from "vue-social-chat";

Vue.use(VueSocialChat);

Vue.use(VueTelInput);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
