<template>
  <div class="center">
    <vs-navbar shadow notLine center-collapsed v-model="active" fixed>
      <template #left>
        <img
          src="@/assets/images/logo.png"
          style="height: 60px; margin: 30px"
          alt=""
        />
      </template>
      <vs-navbar-item
        @click="showInfo('HomePage')"
        class="nav-item-btn"
        :active="active == 'home'"
        id="home"
      >
        Accueil
      </vs-navbar-item>
      <vs-navbar-item
        class="nav-item-btn"
        :active="active == 'services'"
        id="services"
        @click="showInfo('ServicesPage')"
      >
        Services
      </vs-navbar-item>
      <vs-navbar-item
        class="nav-item-btn"
        :active="active == 'license'"
        id="license"
        @click="showInfo('BranchesPage')"
      >
        Filiales
      </vs-navbar-item>
      <vs-navbar-item
        class="nav-item-btn"
        :active="active == 'license'"
        id="license"
        @click="showInfo('NewsPage')"
      >
        Actualités
      </vs-navbar-item>
    </vs-navbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    active: "guide",
  }),
  methods: {
    showInfo(page) {
      setTimeout(() => {
        this.$router
          .push({
            name: page,
          })
          .catch(() => {});
      }, 300);
    },
    scrollToServices() {
      this.$emit("scrollToServices");
    },
  },
};
</script>
<style scoped>
.nav-item-btn {
  font-size: 1.3rem;
}
</style>
